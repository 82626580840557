/* Import video quality levels plugin. */
import 'videojs-contrib-quality-levels'
/* Import quality selector plugin. */
import videojsqualityselector from 'videojs-hls-quality-selector'

/* Import social sharing plugin. */
import 'videojs-share'


export function initialize_share(player) {
    /* Initialize sharing plugin. Currently contains dummy values. */
    const share_options = {
        socials: ['messenger', 'whatsapp', 'tw', 'email'],

        url: window.location.href,
        title: document.title,
        image: 'https://dummyimage.com/1200x630',

        // required for Facebook and Messenger
        fbAppId: '12345',
        // optional for Facebook
        redirectUri: `${window.location.href}#close`,
    }

    if (!player.shareEmbed) {
        player.share(share_options);
        return;
    }


    fetch(player.shareEmbed)
      .then((response) => response.json())
      .then((response) => {
        if (response.embed) {
            share_options.embedCode = response?.embed.replace(/"/g, '&quot;');
        }
      }).finally(() => {
        player.share(share_options);
      });
}

export function initialize_quality(player) {
    /* Initialize quality selector plugin. */
    player.hlsQualitySelector = videojsqualityselector
    player.hlsQualitySelector({
        vjsIconClass: 'vjs-icon-cog',
    })
}

export default function initialize_ui(player) {
    initialize_share(player)
    initialize_quality(player)
}
